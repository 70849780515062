<template lang="">
  <v-stage
    v-if="config != null"
    :config="configKonva"
    style="border: 1px solid"
  >
    <v-layer>
      <div v-for="(obj, index) in config" :key="obj">
        <v-rect
          v-if="obj.type == 'rect'"
          :config="obj"
          @dragend="(value) => handleDragEnd(value, index)"
          @click="(value) => updateValues(obj, index)"
          @dblClick="rotate(index)"
        ></v-rect>
        <v-text
          v-if="obj.type == 'text'"
          :config="obj"
          @dragend="(value) => handleDragEnd(value, index)"
          @click="(value) => updateValues(obj, index)"
        />
      </div>
    </v-layer>
  </v-stage>
</template>
<script>
/* eslint-disable */
export default {
  props: {
    name: {},
    xPos: {},
    yPos: {},
    width: {},
    height: {},
    equipment: {},
    currentIndex: {},
    edit: {
      default: false,
    },
    checkIntersection: {
      default: true,
    },
    KonvaHeight: {
      default: 100,
    },
    KonvaWidth: {
      default: 100,
    },
    config: {},
  },
  watch: {
    xPos(newVal) {
      this.config[this.currentIndex].x = newVal;
    },
    yPos(newVal) {
      this.config[this.currentIndex].y = newVal;
    },
    width(newVal) {
      this.config[this.currentIndex].width = newVal;
    },
    height(newVal) {
      this.config[this.currentIndex].height = newVal;
    },
  },
  mounted() {
    window.addEventListener("resize", this.rezised);
  },
  unmounnted() {
    window.removeEventListener("resize", this.rezised);
  },
  methods: {
    addRect() {},
    rezised() {
      this.configKonva = {
        width: window.innerWidth * (this.KonvaWidth / 100),
        height: window.innerHeight * (this.KonvaHeight / 100),
      };
    },
    rotate(index) {
      this.$emit("update:width", this.config[index].height);
      this.$emit("update:height", this.config[index].width);
    },
    updateValues(value, index) {
      this.$emit("update:currentIndex", index);
      this.$emit("update:name", this.name);
      this.$emit("update:xPos", Math.round(this.config[index].x));
      this.$emit("update:yPos", Math.round(this.config[index].y));
      this.$emit("update:width", this.config[index].width);
      this.$emit("update:height", this.config[index].height);
      this.$emit("update:equipment", this.equipment);
    },
    handleDragEnd(value, index) {
      this.config[index].x = value.target.attrs.x;
      this.config[index].y = value.target.attrs.y;
      this.updateValues(value, index);
    },
  },
  data() {
    return {
      configKonva: {
        width: window.innerWidth * (this.KonvaWidth / 100),
        height: window.innerHeight * (this.KonvaHeight / 100),
      },
    };
  },
};
</script>
<style lang=""></style>
