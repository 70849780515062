<template lang="">
  <ContentTemplate>
    <template v-slot:content>
      <RowTemplate>
        <template v-slot:title> Name </template>

        <template v-slot:action>
          <q-input
            :disable="disable()"
            filled
            :model-value="name"
            @update:model-value="(value) => $emit('update:name', value)"
          ></q-input>
        </template>
      </RowTemplate>
      <q-separator class="q-my-md" />
      <RowTemplate>
        <template v-slot:title> X-Pos.: </template>

        <template v-slot:action>
          <q-input
            :disable="disable()"
            filled
            type="number"
            :model-value="xPos"
            @update:model-value="(value) => $emit('update:xPos', Number(value))"
          ></q-input>
        </template>
      </RowTemplate>
      <div class="q-mt-md"></div>
      <RowTemplate>
        <template v-slot:title> Y-Pos.: </template>

        <template v-slot:action>
          <q-input
            :disable="disable()"
            filled
            type="number"
            :model-value="yPos"
            @update:model-value="(value) => $emit('update:yPos', Number(value))"
          ></q-input>
        </template>
      </RowTemplate>
      <q-separator class="q-my-md" />
      <RowTemplate>
        <template v-slot:title> Breite: </template>

        <template v-slot:action>
          <q-input
            :disable="disable() || groups"
            filled
            type="number"
            :model-value="width"
            @update:model-value="
              (value) => $emit('update:width', Number(value))
            "
          ></q-input>
        </template>
      </RowTemplate>
      <div class="q-mt-md"></div>
      <RowTemplate>
        <template v-slot:title> Höhe: </template>

        <template v-slot:action>
          <q-input
            :disable="disable() || groups"
            filled
            type="number"
            :model-value="height"
            @update:model-value="
              (value) => $emit('update:height', Number(value))
            "
          ></q-input>
        </template>
      </RowTemplate>
      <q-separator class="q-my-md" />
      <div class="text-weight-bold">Ausstatung:</div>
      <q-input
        :disable="disable()"
        filled
        type="textarea"
        :model-value="equipment"
        @update:model-value="(value) => $emit('update:equipment', value)"
      ></q-input>
    </template>
  </ContentTemplate>
</template>
<script>
/* eslint-disable */
import RowTemplate from "@/components/templates/RowTemplate";
import ContentTemplate from "@/components/templates/ContentTemplate";
export default {
  components: { RowTemplate, ContentTemplate },
  props: {
    name: {
      default: "",
    },
    xPos: {},
    yPos: {},
    width: {},
    height: {},
    equipment: {
      default: "",
    },
    edit: {
      default: false,
    },
    groups: {
      default: false,
    },
  },
  methods: {
    disable() {
      if (this.xPos == null) {
        return true;
      }
      if (!this.edit) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang=""></style>
