<template>
  <div class="row">
    <div class="col-3">
      <span class="text-weight-bold">
        <slot name="title" />
      </span>
    </div>
    <div class="col-9">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
