<template lang="">
  <q-layout>
    <q-header elevated>
      <q-toolbar>
        <q-btn dense flat round icon="house" @click="$router.push('/')"></q-btn>
        <q-toolbar-title>Gebäude erstellen</q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <q-page>
        <q-stepper v-model="step" ref="stepper" color="primary" animated>
          <q-step
            :name="1"
            title="Gebäude Informationen"
            icon="settings"
            :done="done1"
          >
            <BuildingInformation
              v-model:name="building.name"
              v-model:address="building.address"
              v-model:location="building.location"
              v-model:country="building.country"
              v-model:postcode="building.postcode"
              v-model:width="building.width"
              v-model:length="building.length"
            />

            <q-stepper-navigation>
              <q-btn
                @click="
                  () => {
                    validationBuilding();
                  }
                "
                color="primary"
                label="Fortfahren"
              />
            </q-stepper-navigation>
          </q-step>

          <q-step :name="2" title="Gebäude speichern" icon="save" :done="done3">
            <q-stepper-navigation>
              <q-btn
                color="primary"
                @click="saveBuilding()"
                label="Speichern"
              />
              <q-btn
                flat
                @click="step = 1"
                color="primary"
                label="Back"
                class="q-ml-sm"
              />
            </q-stepper-navigation>
          </q-step>
        </q-stepper>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
/* eslint-disable */
import { ref } from "vue";
import BuildingInformation from "@/components/BuildingInformation.vue";
import { addDoc, collection, updateDoc, doc } from "firebase/firestore";
import { BUILDING } from "@/constants";
import { db } from "@/firebase";
import { uploadJSON } from "@/firebase/storage";
export default {
  data() {
    return {
      building: {
        name: "",
        location: "",
        address: "",
        postcode: "",
        country: "",
        width: null,
        length: null,
      },
    };
  },
  components: { BuildingInformation },
  methods: {
    validationBuilding() {
      if (
        this.building.name == "" ||
        this.building.location == "" ||
        this.building.address == "" ||
        this.building.postcode == "" ||
        this.building.country == "" ||
        this.building.width == "" ||
        this.building.length == ""
      ) {
        alert("Es müssen alle Felder ausgefüllt werden.");
        return;
      }
      this.done1 = true;
      this.step = 2;
    },
    async saveBuilding() {
      const document = await addDoc(collection(db, BUILDING), this.building);
      const floor = await addDoc(
        collection(db, `${BUILDING}/${document.id}/floors`),
        { name: "Erdgeschoss", url: "", index: 0 }
      );
      const json = await uploadJSON(document.id, floor.id, []);
      await updateDoc(
        doc(db, `${BUILDING}/${document.id}/floors/${floor.id}`),
        json
      );
      this.$router.push(`/buildingview/${document.id}?edit=true`);
    },
  },
  setup() {
    const step = ref(1);
    const done1 = ref(false);
    const done2 = ref(false);
    const done3 = ref(false);

    return {
      step,
      done1,
      done2,
      done3,

      reset() {
        done1.value = false;
        done2.value = false;
        done3.value = false;
        step.value = 1;
      },
    };
  },
};
</script>
<style lang=""></style>
