<template>
  <q-layout class="page">
    <q-header elevated>
      <div style="height: 5rem" class="row justify-evenly items-center page">
        <div class="col-4">
          <div style="font-size: 40px; font-family: cursive; font-weight: bold">
            FloorExplorer
          </div>
        </div>
        <div class="col-4">
          <div class="row">
            <q-btn
              rounded
              label="Übersicht der Gebäude"
              class="white-button"
              @click="$router.push('/overview')"
            />
            <q-space></q-space>
            <q-btn
              class="red-button"
              rounded
              label="Erstellen eines Gebäudes"
              @click="$router.push('/createBuilding')"
            />
          </div>
        </div>
      </div>
    </q-header>
    <q-page-container>
      <div style="height: 10rem"></div>
      <q-space></q-space>
      <div class="column">
        <div class="row justify-center items-center">
          <div class="col-5">
            <div
              style="
                font-size: 32px;
                font-family: Verdana;
                font-weight: normal;
                color: white;
              "
            >
              2D-Etagen- und Raumvisualisierung der Ausstattung von Felix
              Althaus, Jonas Ittermann & Felix Tigges.
            </div>
            <p></p>
            <q-btn
              class="red-button"
              rounded
              label="Erstellen eines Gebäudes"
              @click="$router.push('/createBuilding')"
            />
          </div>

          <!-- Rechte Div (Leer) -->
          <div class="col-3"></div>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: "LandingPage",
};
</script>

<style scoped>
.page {
  background: linear-gradient(to right, #145dcc, #233477);
  height: 100vh; /* Für Demonstration. Dies stellt sicher, dass das Gradient den gesamten sichtbaren Bereich des Browsers ausfüllt */
  width: 100vw; /* Hier können Sie das hellere Blau anpassen, wenn Sie möchten */
}
.red-button {
  font-weight: bold;
  font-size: small;
  background-color: #ff6666;
  color: white;
  transition: transform 0.3s;
  width: 250px;
}
.red-button:hover {
  transform: scale(1.1); /* Button wird beim Hovern ein wenig größer */
}
.white-button {
  font-weight: bold;
  font-size: small;
  background-color: white;
  color: black;
  transition: transform 0.3s;
  width: 250px;
  padding: 10px 0px 10px 0px;
}
.white-button:hover {
  transform: scale(1.1); /* Button wird beim Hovern ein wenig größer */
}
</style>
