<template>
  <q-layout v-if="loading">temp</q-layout>
  <q-layout view="hHh lpr lFr" v-else>
    <q-header v-if="!embed" elevated class="bg-primary text-white">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />
        <q-btn dense flat round icon="house" @click="$router.push('/')"></q-btn>
        <q-toolbar-title> FloorExplorer </q-toolbar-title>
        <q-btn
          v-if="edit"
          dense
          flat
          round
          icon="add"
          @click="addRoomdialog = true"
        />
        <q-btn dense flat round icon="info" @click="infoDialog = true"></q-btn>
        <q-btn
          v-if="edit"
          dense
          flat
          round
          icon="save"
          @click="saveBuildingDialog = true"
        ></q-btn>

        <q-btn dense flat round icon="menu" @click="toggleRightDrawer" />
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="!(embed && floorID != '')"
      show-if-above
      v-model="leftDrawerOpen"
      side="left"
      bordered
      :breakpoint="embed ? 0 : 1023"
    >
      <FloorsOverview
        v-model:floors="floors"
        v-model:tab="tab"
        :docID="$route.params.id"
        :edit="edit"
      />
    </q-drawer>

    <q-drawer
      v-if="!embed"
      show-if-above
      v-model="rightDrawerOpen"
      side="right"
      bordered
    >
      <AddRoomTool
        v-model:name="activeName"
        v-model:xPos="activeXPos"
        v-model:yPos="activeYPos"
        v-model:width="activeWidth"
        v-model:height="activeHeight"
        v-model:equipment="activeEquipment"
        :edit="edit"
        :groups="true"
      />
    </q-drawer>

    <q-page-container>
      <FloorView
        v-model:config="tab.config"
        v-model:name="activeName"
        v-model:xPos="activeXPos"
        v-model:yPos="activeYPos"
        v-model:width="activeWidth"
        v-model:height="activeHeight"
        v-model:equipment="activeEquipment"
        :buildingWidth="building.width"
        :buildingLength="building.length"
        :edit="edit"
      />
    </q-page-container>

    <q-dialog
      v-model="addRoomdialog"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="">
        <q-bar class="bg-primary">
          <q-space />

          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Schließen</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section>
          <RoomEditor ref="roomEditor" />
        </q-card-section>
        <q-card-actions>
          <q-btn
            label="Speichern"
            class="bg-primary text-white"
            @click="addRoom()"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="infoDialog">
      <q-card style="width: 700px">
        <q-bar class="bg-primary">
          <q-space />

          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Schließen</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section
          ><BuildingInformation
            v-model:name="building.name"
            v-model:address="building.address"
            v-model:location="building.location"
            v-model:country="building.country"
            v-model:postcode="building.postcode"
            v-model:width="building.width"
            v-model:length="building.length"
            :disable="true"
        /></q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="saveBuildingDialog">
      <q-card class="my-card">
        <q-card-section>
          <div class="text-h6">Wirklich speichern?</div>
        </q-card-section>
        <q-card-actions>
          <q-btn
            unelevated
            dense
            v-close-popup
            color="grey"
            label="Schließen" />
          <q-btn
            unelevated
            dense
            @click="save()"
            color="primary"
            label="Speichern"
        /></q-card-actions>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
/* eslint-disable */
import { ref } from "vue";
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { BUILDING } from "@/constants";
import FloorsOverview from "@/components/FloorsOverview.vue";
import AddRoomTool from "@/components/AddRoomTool.vue";
import MainContent from "@/components/MainContent.vue";
import BuildingInformation from "@/components/BuildingInformation.vue";
import RoomEditor from "@/components/RoomEditor.vue";
import FloorView from "@/components/FloorView.vue";
import { uploadJSON } from "@/firebase/storage";
export default {
  components: {
    FloorsOverview,
    AddRoomTool,
    MainContent,
    BuildingInformation,
    RoomEditor,
    FloorView,
  },
  data() {
    return {
      activeName: "",
      activeXPos: null,
      activeYPos: null,
      activeWidth: null,
      activeHeight: null,
      activeEquipment: "",
      floors: null,
      tab: { config: [] },
      edit: false,
      embed: false,
      floorID: "",
      addRoomdialog: ref(false),
      infoDialog: ref(false),
      maximizedToggle: ref(true),
      building: {},
      loading: true,
    };
  },

  async mounted() {
    this.loading = true;
    this.edit =
      this.$route.query.edit != undefined ? this.$route.query.edit : false;
    this.embed =
      this.$route.query.embed != undefined ? this.$route.query.embed : false;
    this.floorID =
      this.$route.query.floor != undefined ? this.$route.query.floor : "";
    const building = await getDoc(
      doc(db, `${BUILDING}/${this.$route.params.id}`)
    );
    this.building = building.data();
    const streamFloors = query(
      collection(db, `${BUILDING}/${this.$route.params.id}/floors`)
    );
    onSnapshot(streamFloors, async (querySnapshot) => {
      const floors = [];
      querySnapshot.forEach(async (doc) => {
        let data = doc.data();
        data["documentID"] = doc.id;
        floors.push(data);
      });
      floors.sort((a, b) => b.index - a.index);
      for (let index in floors) {
        floors[index].buildingWidth = this.building.width;
        floors[index].buildingLength = this.building.length;
        floors[index].config = await (await fetch(floors[index].url)).json();
      }
      this.floors = floors;

      this.tab = this.floors[0];
      if (this.floorID != "") {
        this.tab = this.floors.find((obj) => obj.documentID === this.floorID);
      }
    });
    this.loading = false;
  },
  methods: {
    addRoom() {
      const room = this.$refs.roomEditor.getObject();
      this.tab.config.push({
        name: room.name,
        equipment: room.equipment,
        x: 0,
        y: 0,
        width: room.config[0].width,
        height: room.config[0].height,
        room: room.config,
      });
    },
    async save() {
      for (let index in this.floors) {
        const ref = doc(
          db,
          `${BUILDING}/${this.$route.params.id}/floors/${this.floors[index].documentID}`
        );
        const json = await uploadJSON(
          this.$route.params.id,
          this.floors[index].documentID,
          this.floors[index].config
        );
        await updateDoc(ref, json);
        this.saveBuildingDialog = false;
      }
      location.reload();
    },
  },
  setup() {
    const leftDrawerOpen = ref(false);
    const rightDrawerOpen = ref(false);
    const addBuildingDialog = ref(false);
    const saveBuildingDialog = ref(false);

    return {
      leftDrawerOpen,
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },

      rightDrawerOpen,
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value;
      },
      addBuildingDialog,
      saveBuildingDialog,
    };
  },
};
</script>
