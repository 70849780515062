<template lang="">
  <div style="padding: 50px" class="column items-center">
    <div :style="containerStyle()">
      <v-stage :config="configKonva">
        <v-layer>
          <v-group
            v-for="(obj, index) in config"
            :config="draggableObject(obj)"
            :key="obj"
            @dragmove="(value) => handleDragMove(value, obj, index)"
            @dragend="(value) => handleDragEnd(value, index)"
            @click="(value) => (edit ? handleClick(value, index) : null)"
            @dblclick="(value) => (edit ? dblClick(value, index) : null)"
            @mousemove="(value) => mouseOver(value, index)"
            @mouseout="showEquipment = false"
          >
            <div v-for="room in obj.room" :key="room">
              <v-rect v-if="room.type == 'rect'" :config="room"></v-rect>
              <v-text v-if="room.type == 'text'" :config="room" />
            </div>
          </v-group>
        </v-layer>
      </v-stage>
      <div
        v-if="!edit"
        :style="equipmentDivStyle()"
        @click="showEquipment = false"
        v-show="showEquipment"
        @mousemove="showEquipment = true"
        @mouseout="showEquipment = false"
      >
        <q-card style="min-width: 300px">
          <q-card-section>Raum: {{ this.name }}</q-card-section>
          <!--  <q-card-section>Breite: {{ this.width / 100 }} Meter</q-card-section>
          <q-card-section>Länge: {{ this.height / 100 }} Meter</q-card-section>-->

          <q-card-section v-if="equipment"
            >Ausstatung:
            <div v-html="equipment.replaceAll('\n', '<br>')"></div
          ></q-card-section>
        </q-card>
      </div>
    </div>
  </div>

  <q-dialog
    v-model="changeRoomDialog"
    persistent
    :maximized="true"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="">
      <q-bar class="bg-primary">
        <q-space />

        <q-btn dense flat icon="close" color="white" v-close-popup>
          <q-tooltip class="bg-white text-primary">Schließen</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section>
        <RoomEditor ref="roomEditor" v-model:config="activeConfig" />
      </q-card-section>
      <q-card-actions>
        <q-btn
          label="Speichern"
          class="bg-primary text-white"
          @click="editRoom()"
          v-close-popup
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import RoomEditor from "./RoomEditor.vue";
/* eslint-disable */
export default {
  data() {
    return {
      currentIndex: 0,
      changeRoomDialog: false,
      temp: true,
      activeConfig: [],
      configKonva: {
        width: this.buildingWidth * 100,
        height: this.buildingLength * 100,
      },
      divPositionX: 0,
      divPositionY: 0,
      showEquipment: false,
    };
  },
  components: {
    RoomEditor,
  },
  props: {
    config: {},
    name: {
      default: "",
    },
    xPos: {},
    yPos: {},
    width: {},
    height: {},
    equipment: {
      default: "",
    },
    buildingWidth: {},
    buildingLength: {},
    edit: {
      default: false,
    },
  },
  watch: {
    name(newVal) {
      this.config[this.currentIndex].name = newVal;
    },
    equipment(newVal) {
      this.config[this.currentIndex].equipment = newVal;
    },
    xPos(newVal) {
      this.config[this.currentIndex].x = newVal;
    },
    yPos(newVal) {
      this.config[this.currentIndex].y = newVal;
    },
    width(newVal) {
      this.config[this.currentIndex].width = newVal;
    },
    height(newVal) {
      this.config[this.currentIndex].height = newVal;
    },
  },
  methods: {
    mouseOver(value, index) {
      this.divPositionX = value.evt.pageX - 20;
      this.divPositionY = value.evt.pageY - 20;
      this.updateValues(value, index);
      this.showEquipment = true;
    },
    draggableObject(obj) {
      obj.draggable = this.edit;
      return obj;
    },
    equipmentDivStyle() {
      return {
        // position: absolute; top: ${this.event.pageY}px; left: ${this.event.pageX}px
        position: "absolute",
        left: this.divPositionX + "px",
        top: this.divPositionY + "px",
      };
    },
    containerStyle() {
      return {
        border: "1px solid",
        "border-radius": "5px",
        width: `${this.buildingWidth * 100}px`,
        "background-color": "white",
      };
    },
    editRoom() {
      const conf = this.$refs.roomEditor.getObject();
      this.config[this.currentIndex] = {
        name: conf.name,
        equipment: conf.equipment,
        x: 0,
        y: 0,
        width: conf.config[0].width,
        height: conf.config[0].height,
        room: conf.config,
      };
    },
    handleClick(value, index) {
      this.updateValues(value, index);
    },
    updateValues(value, index) {
      this.currentIndex = index;
      this.$emit("update:name", this.config[index].name);
      this.$emit("update:xPos", Math.round(this.config[index].x));
      this.$emit("update:yPos", Math.round(this.config[index].y));
      this.$emit("update:width", this.config[index].width);
      this.$emit("update:height", this.config[index].height);
      this.$emit("update:equipment", this.config[index].equipment);
    },
    dblClick(value, index) {
      this.currentIndex = index;
      this.changeRoomDialog = true;
      this.activeConfig = this.config[index];
    },
    handleDragEnd(value, index) {
      this.config[index].x = value.target.attrs.x;
      this.config[index].y = value.target.attrs.y;
      this.updateValues(value, index);
    },
    handleDragMove(value, obj, index) {
      this.config.forEach((group) => {
        if (obj === group) {
          return;
        }
        let target = value.target.getClientRect();
        let abs = value.target.absolutePosition();
        let evt = value.evt;
        if (this.haveIntersection(group, target)) {
          group.stroke = "red";
          if (
            group.width * (evt.layerY - group.y) <=
              group.height * (evt.layerX - group.x) &&
            -group.width * (evt.layerY - group.y) <=
              group.height * (evt.layerX - (group.x + group.width))
          ) {
            //Rechte Seite
            abs.x = group.x + group.width;
          } else if (
            -group.width * (evt.layerY - group.y) <=
              group.height * (evt.layerX - (group.x + group.width)) &&
            -group.width * (evt.layerY - (group.y + group.height)) <=
              -group.height * (evt.layerX - (group.x + group.width))
          ) {
            //Unten
            abs.y = group.y + group.height;
          } else if (
            -group.width * (evt.layerY - (group.y + group.height)) <=
              -group.height * (evt.layerX - (group.x + group.width)) &&
            group.width * (evt.layerY - (group.y + group.height)) <=
              -group.height * (evt.layerX - group.x)
          ) {
            //Linke Seite
            abs.x = group.x - target.width;
          } else {
            //Oben
            abs.y = group.y - target.height;
          }

          this.config[index].x = abs.x;
          this.config[index].y = abs.y;
          value.target.absolutePosition(abs);
        } else {
          group.stroke = "black";
        }
      });
    },
    haveIntersection(r1, r2) {
      return !(
        r2.x > r1.x + r1.width ||
        r2.x + r2.width < r1.x ||
        r2.y > r1.y + r1.height ||
        r2.y + r2.height < r1.y
      );
    },
  },
};
</script>
<style scoped>
.classdiv {
  position: fixed;
  left: 10px;
  top: 10px;
}
</style>
