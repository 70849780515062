<template>
  <div id="RoomToolbox">
    <q-btn color="primary" label="Objekt Hinzufügen">
      <q-menu>
        <q-list style="min-width: 100px">
          <q-item
            v-for="conf in options"
            :key="conf"
            @click="add(conf)"
            clickable
            v-close-popup
          >
            <q-item-section>{{ conf }}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
    <div style="width: 30px"></div>
    <q-btn color="red" label="Objekt löschen" @click="deleteObject()"></q-btn>
  </div>
</template>
<script>
export default {
  props: {
    config: {},
    currentIndex: {
      default: null,
    },
    name: {},
  },
  setup() {
    const options = [
      "Text",
      "Tür",
      "Durchgang",
      "Fenster",
      "Stuhl",
      "Tisch",
      "Wand",
    ];

    return {
      options,
    };
  },
  data() {
    return {
      temp: [],
    };
  },
  methods: {
    deleteObject() {
      if (this.currentIndex == 0) {
        return;
      }
      if (this.currentIndex == null) {
        return;
      }
      let c = this.config;
      c.splice(this.currentIndex, 1);
      this.$emit("update:config", c);
    },
    add(value) {
      let c = this.config;
      if (value == "Text") {
        c.push({
          type: "text",
          text: this.name,
          fontSize: 16,
          x: 100,
          y: 100,
          draggable: true,
        });
        this.$emit("update:config", c);
        return;
      } else if (value == "Tür") {
        c.push({
          type: "rect",
          label: "Tür",
          x: 100,
          y: 100,
          width: 75,
          height: 5,
          fill: "brown",
          stroke: "black",
          strokeWidth: 1,
          draggable: true,
        });
        this.$emit("update:config", c);
        return;
      } else if (value == "Durchgang") {
        c.push({
          type: "rect",
          label: "Durchgang",
          x: 100,
          y: 100,
          width: 75,
          height: 5,
          fill: "#b0b0b0",
          stroke: "black",
          strokeWidth: 0,
          draggable: true,
        });
        this.$emit("update:config", c);
        return;
      } else if (value == "Fenster") {
        c.push({
          type: "rect",
          label: "Fenster",
          x: 100,
          y: 100,
          width: 75,
          height: 5,
          fill: "lightblue",
          stroke: "black",
          strokeWidth: 1,
          draggable: true,
        });
        this.$emit("update:config", c);
        return;
      } else if (value == "Stuhl") {
        c.push({
          type: "rect",
          label: "Stuhl",
          x: 100,
          y: 100,
          width: 20,
          height: 20,
          fill: "#eb8b05",
          stroke: "black",
          strokeWidth: 1,
          draggable: true,
        });
        this.$emit("update:config", c);
        return;
      } else if (value == "Tisch") {
        c.push({
          type: "rect",
          label: "Tisch",
          x: 100,
          y: 100,
          width: 50,
          height: 20,
          fill: "#cf7902",
          stroke: "black",
          strokeWidth: 1,
          draggable: true,
        });
        this.$emit("update:config", c);
        return;
      } else if (value == "Wand") {
        c.push({
          type: "rect",
          label: "Wand",
          x: 100,
          y: 100,
          width: 2,
          height: 50,
          fill: "black",
          stroke: "black",
          strokeWidth: 0,
          draggable: true,
        });
        this.$emit("update:config", c);
        return;
      }
    },
  },
};
</script>
<style scoped>
#RoomToolbox {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-style: solid;
  border-top-color: grey;
  border-top-width: 1px;
}
</style>
