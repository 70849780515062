import { createRouter, createWebHashHistory } from "vue-router";
import BuildingView from "../views/HomeView.vue";
import LandingPage from "../views/LandingPage.vue";
import OverviewBuildings from "../views/OverviewBuildings.vue";
import RoomEditor from "../components/RoomEditor.vue";
import CreateBuilding from "../views/CreateBuilding";
const routes = [
  {
    path: "/buildingview/:id",
    name: "home",
    component: BuildingView,
  },
  {
    path: "/",
    name: "start",
    component: LandingPage,
  },
  {
    path: "/overview",
    name: "overview",
    component: OverviewBuildings,
  },
  {
    path: "/roomeditor",
    name: "Roomeditor",
    component: RoomEditor,
  },
  {
    path: "/createBuilding",
    name: "CreateBuilding",
    component: CreateBuilding,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
