<template lang="">
  <q-input
    :disable="disable"
    class="input"
    :model-value="name"
    @update:model-value="(value) => $emit('update:name', value)"
    label="Name"
    :rules="[
      (val) =>
        (!!val && /^[A-Za-z0-9\s\.,#-ßüöä]+$/.test(val)) ||
        'Dieses Feld ist erforderlich.',
    ]"
  >
    <template v-slot:prepend>
      <q-icon name="label" />
    </template>
  </q-input>
  <q-input
    :disable="disable"
    class="input"
    :model-value="address"
    @update:model-value="(value) => $emit('update:address', value)"
    label="Adresse"
    :rules="[
      (val) =>
        (!!val && /^[A-Za-z0-9\s\.,#-ßüöä]+$/.test(val)) || 'Adresse ungültig.',
    ]"
  >
    <template v-slot:prepend>
      <q-icon name="place" />
    </template>
  </q-input>
  <div class="row">
    <q-input
      :disable="disable"
      class="input"
      style="flex: 3"
      :model-value="location"
      @update:model-value="(value) => $emit('update:location', value)"
      label="Ort"
      :rules="[
        (val) =>
          (!!val && /^[A-Za-z0-9\s\.,#-ßüöä]+$/.test(val)) || 'Ort ungültig.',
      ]"
    >
      <template v-slot:prepend>
        <q-icon name="location_city" />
      </template>
    </q-input>

    <q-input
      :disable="disable"
      class="input"
      style="flex: 1"
      :model-value="postcode"
      @update:model-value="(value) => $emit('update:postcode', value)"
      label="PLZ"
      :rules="[
        (val) =>
          (!!val && val.length <= 5 && !isNaN(val)) || 'Postleitzahl ungültig.',
      ]"
    >
      <template v-slot:prepend>
        <q-icon name="mail" />
      </template>
    </q-input>
  </div>
  <q-select
    :disable="disable"
    class="input"
    :model-value="country"
    @update:model-value="(value) => $emit('update:country', value)"
    :options="laender"
    label="Land"
  >
    <template v-slot:prepend>
      <q-icon name="flag" />
    </template>
  </q-select>
  <div class="row">
    <q-input
      :disable="disable"
      class="input"
      style="flex: 1"
      type="number"
      :model-value="length"
      @update:model-value="(value) => $emit('update:length', Number(value))"
      label="Länge in Meter"
    ></q-input>
    <q-input
      :disable="disable"
      class="input"
      style="flex: 1"
      type="number"
      :model-value="width"
      @update:model-value="(value) => $emit('update:width', Number(value))"
      label="Breite in Meter"
    ></q-input>
  </div>
</template>
<script>
export default {
  props: {
    name: {},
    address: {},
    location: {},
    country: {},
    postcode: {},
    width: {},
    length: {},
    disable: {
      defeault: false,
    },
  },
  data() {
    return {
      laender: [
        "🇩🇪 Deutschland",
        "🇦🇹 Österreich",
        "🇧🇪 Belgien",
        "🇧🇬 Bulgarien",
        "🇩🇰 Dänemark",
        "🇪🇪 Estland",
        "🇫🇮 Finnland",
        "🇫🇷 Frankreich",
        "🇬🇷 Griechenland",
        "🇮🇪 Irland",
        "🇮🇹 Italien",
        "🇭🇷 Kroatien",
        "🇱🇻 Lettland",
        "🇱🇹 Litauen",
        "🇱🇺 Luxemburg",
        "🇲🇹 Malta",
        "🇳🇱 Niederlande",
        "🇵🇱 Polen",
        "🇵🇹 Portugal",
        "🇷🇴 Rumänien",
        "🇸🇪 Schweden",
        "🇸🇰 Slowakei",
        "🇸🇮 Slowenien",
        "🇪🇸 Spanien",
        "🇨🇿 Tschechien",
        "🇭🇺 Ungarn",
        "🇨🇾 Zypern",
      ],
    };
  },
};
</script>
<style scoped>
.input {
  margin-right: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
</style>
