<template>
  <div class="column q-mt-lg">
    <slot name="content" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
