// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDS8CDzvJyL2586VUsIz4iNSjzMvnO5804",
  authDomain: "floorexplorer-554cc.firebaseapp.com",
  projectId: "floorexplorer-554cc",
  storageBucket: "floorexplorer-554cc.appspot.com",
  messagingSenderId: "743009300771",
  appId: "1:743009300771:web:c2cb022233bc11fa845c96",
};
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
