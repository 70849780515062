import { BUILDING } from "@/constants";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from ".";
async function uploadJSON(building, documentID, json) {
  const data = JSON.stringify(json);
  const blob = new Blob([data], { type: "text/plain" });
  let path = `${BUILDING}/${building}/${documentID}.json`;
  const storageRef = ref(storage, path);
  const metadata = {
    contentType: "application/json",
  };

  const snapshot = await uploadBytes(storageRef, blob, metadata);
  const url = await getDownloadURL(snapshot.ref);
  return { url: url, path: path };
}

export { uploadJSON };
