<template>
  <q-layout>
    <!-- Obere Leiste -->
    <q-header class="bg-primary">
      <q-toolbar>
        <q-btn icon="home" flat round @click="$router.push('/')"></q-btn>
        <q-toolbar-title> Gebäudeübersicht </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <!-- Hauptinhalt -->
    <q-page-container>
      <q-page class="q-pa-md">
        <q-table
          :filter="filter"
          :columns="columns"
          :rows="buildings"
          dense
          row-key="name"
          title="Übersicht"
        >
          <template v-slot:top-right>
            <q-input
              borderless
              dense
              debounce="300"
              v-model="filter"
              placeholder="Search"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </template>
          <template v-slot:body-cell-actions="props">
            <q-td :props="props">
              <!-- Hier können Sie Ihre Aktionsschaltflächen hinzufügen, z.B.: -->
              <q-btn
                size="sm"
                flat
                round
                icon="preview"
                @click="preview(props.row.documentID)"
              >
                <q-tooltip>Vorschau</q-tooltip>
              </q-btn>
              <q-btn
                size="sm"
                flat
                round
                icon="edit"
                @click="edit(props.row.documentID)"
                ><q-tooltip>Bearbeiten</q-tooltip></q-btn
              >
              <q-btn
                size="sm"
                flat
                round
                icon="delete"
                @click="deleteBuilding()"
                ><q-tooltip>Löschen</q-tooltip></q-btn
              >
            </q-td>
          </template>
        </q-table>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  data() {
    return {
      filter: "",
      columns: [
        { name: "name", label: "Name", align: "left", field: "name" },
        { name: "address", label: "Adresse", align: "left", field: "address" },
        { name: "country", label: "Land", align: "left", field: "country" },
        { name: "location", label: "Ort", align: "left", field: "location" },
        {
          name: "postcode",
          label: "Postleitzahl",
          align: "left",
          field: "postcode",
        },
        { name: "actions", label: "Aktionen", align: "left", field: "actions" },
      ],
    };
  },
  methods: {
    preview(docID) {
      this.$router.push(`/buildingview/${docID}`);
    },
    edit(docID) {
      this.$router.push(`/buildingview/${docID}?edit=true`);
    },
    deleteBuilding() {
      // @TODO: Delete Funktion einfügen
    },
  },
  computed: {
    buildings() {
      return this.$store.state.buildings;
    },
  },
};
</script>

<style scoped></style>
