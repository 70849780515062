<template>
  <div class="q-gutter-y-md" style="max-width: 400px">
    <div class="column items-center q-pt-md">
      <q-btn
        v-if="edit"
        color="primary"
        label="Etage Hinzufügen"
        @click="addFloorDialog = true"
      ></q-btn>
    </div>
    <div
      v-for="(floor, index) in floors"
      :key="floor"
      @click="changeFloor(floor, index)"
      :class="tab == floor ? 'selected' : ''"
      class="row justify-between tab"
    >
      <span class="text-weight-bold self-center q-pl-md">{{ floor.name }}</span>

      <span
        ><q-btn
          v-if="edit && index != floors.length - 1"
          flat
          round
          icon="expand_more"
          @click="moveDown(index)"
        ></q-btn>

        <q-btn
          v-if="edit && index != 0"
          flat
          round
          icon="expand_less"
          @click="moveUp(index)"
        ></q-btn>
        <q-btn v-else flat round></q-btn>
      </span>
    </div>
  </div>
  <q-dialog v-model="addFloorDialog">
    <q-card class="my-card">
      <q-card-section>
        <div class="text-h6">Etage hinzufügen</div>
      </q-card-section>
      <q-card-section>
        <q-input label="Name der Etage" dense v-model="input" filled> </q-input
      ></q-card-section>
      <q-card-actions>
        <q-btn unelevated dense v-close-popup color="grey" label="Schließen" />
        <q-btn
          unelevated
          dense
          @click="addFloor(input)"
          color="primary"
          label="Speichern"
      /></q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
/* eslint-disable */
import { BUILDING } from "@/constants";
import { db } from "@/firebase";
import { addDoc, doc, updateDoc, collection } from "firebase/firestore";
import { uploadJSON } from "@/firebase/storage";
export default {
  props: {
    floors: {
      default: [],
    },
    docID: {
      default: null,
    },
    tab: {
      default: {},
    },
    edit: {
      default: false,
    },
  },
  data() {
    return {
      input: "",
      addFloorDialog: false,
    };
  },
  methods: {
    moveUp(index) {
      let currentFloor = this.floors[index];
      let movedFloor = this.floors[index - 1];
      updateDoc(
        doc(db, `${BUILDING}/${this.docID}/floors/${currentFloor.documentID}`),
        {
          index: movedFloor.index,
        }
      );
      updateDoc(
        doc(db, `${BUILDING}/${this.docID}/floors/${movedFloor.documentID}`),
        {
          index: currentFloor.index,
        }
      );
      this.$emit("update:tab", this.floors[0]);
    },
    moveDown(index) {
      let currentFloor = this.floors[index];
      let movedFloor = this.floors[index + 1];
      updateDoc(
        doc(db, `${BUILDING}/${this.docID}/floors/${currentFloor.documentID}`),
        {
          index: movedFloor.index,
        }
      );
      updateDoc(
        doc(db, `${BUILDING}/${this.docID}/floors/${movedFloor.documentID}`),
        {
          index: currentFloor.index,
        }
      );
      this.$emit("update:tab", this.floors[0]);
    },
    changeFloor(tab, index) {
      this.floors[index] = tab;
      this.$emit("update:tab", tab);
      this.$emit("update:floors", this.floors);
    },
    async addFloor(value) {
      const floor = await addDoc(
        collection(db, `${BUILDING}/${this.docID}/floors`),
        { name: this.input, url: "", index: this.floors.length }
      );
      const json = await uploadJSON(this.docID, floor.id, []);
      console.log(json);
      await updateDoc(
        doc(db, `${BUILDING}/${this.docID}/floors/${floor.id}`),
        json
      );
      this.$emit("update:tab", {
        name: value,
        documentID: doc.id,
        config: [],
      });
      this.addFloorDialog = false;
      location.reload();
    },
  },
};
</script>
<style scoped>
.my-card {
  width: 100%;
  min-width: 250px;
}

.tab:hover {
  background-color: rgba(128, 128, 128, 0.24);
  cursor: pointer;
  margin: 10px 10px 0px 10px;
  border-radius: 4px;
}

.tab {
  margin: 10px 10px 0px 10px;
  border-radius: 4px;
}
.selected {
  background-color: rgba(128, 128, 128, 0.493);
  margin: 10px 10px 0px 10px;
  border-radius: 4px;
}
</style>
