<template>
  <div id="RoomEditor">
    <MainContent
      v-model:name="activeName"
      v-model:xPos="activeXPos"
      v-model:yPos="activeYPos"
      v-model:width="activeWidth"
      v-model:height="activeHeight"
      v-model:equipment="activeEquipment"
      v-model:config="canvasObjects.config"
      v-model:currentIndex="currentIndex"
      :checkIntersection="false"
      :KonvaHeight="75"
      :KonvaWidth="75"
    />

    <AddRoomTool
      style="width: 20%"
      v-model:name="activeName"
      v-model:xPos="activeXPos"
      v-model:yPos="activeYPos"
      v-model:width="activeWidth"
      v-model:height="activeHeight"
      v-model:equipment="activeEquipment"
      :edit="$route.query.edit != undefined ? this.$route.query.edit : false"
    />
  </div>
  <RoomToolbox
    style="width: 100%"
    v-model:name="activeName"
    v-model:currentIndex="currentIndex"
    v-model:config="canvasObjects.config"
  />
</template>

<script>
import AddRoomTool from "@/components/AddRoomTool.vue";
import MainContent from "@/components/MainContent.vue";
import RoomToolbox from "@/components/RoomToolbox.vue";
export default {
  components: { AddRoomTool, MainContent, RoomToolbox },
  methods: {
    getObject() {
      this.canvasObjects.name = this.activeName;
      this.canvasObjects.equipment = this.activeEquipment;
      for (let index in this.canvasObjects.config) {
        this.canvasObjects.config[index].draggable = false;
      }
      return this.canvasObjects;
    },
  },
  props: {
    config: { default: null },
  },
  mounted() {
    if (this.config != null) {
      this.activeName = this.config.name;
      this.activeEquipment = this.config.equipment;
      this.canvasObjects.name = this.config.name;
      this.canvasObjects.equipment = this.config.equipment;
      this.canvasObjects.config = this.config.room;
      for (let index in this.canvasObjects.config) {
        if (index == 0) {
          continue;
        }
        this.canvasObjects.config[index].draggable = true;
      }
    }
  },
  data() {
    return {
      activeName: "",
      activeXPos: null,
      activeYPos: null,
      activeWidth: null,
      activeHeight: null,
      activeEquipment: "",
      currentIndex: null,
      canvasObjects: {
        name: "",
        equipment: "",
        config: [
          {
            type: "rect",
            label: "Fläche",
            x: 0,
            y: 0,
            width: 400,
            height: 200,
            fill: "#c7c6c3",
            stroke: "black",
            strokeWidth: 1,
            draggable: false,
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
#RoomEditor {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
</style>
